/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2021 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

:root {
  --prussian-blue-pb-900: #002a5b;
  --prussian-blue-pb-800: #073b72;
  --prussian-blue-pb-700: #10447f;
  --prussian-blue-pb-600: #1c4e8a;
  --prussian-blue-pb-500: #245693;
  --prussian-blue-pb-400: #4b6ea1;
  --prussian-blue-pb-300: #6c87b0;
  --prussian-blue-pb-200: #95a9c7;
  --prussian-blue-pb-100: #bfcade;
  --prussian-blue-pb-050: #e6eaf0;
  --neon-blue-nb-900: #0000b6;
  --neon-blue-nb-800: #0017cf;
  --neon-blue-nb-700: #0027da;
  --neon-blue-nb-600: #1634e6;
  --neon-blue-nb-500: #1b3df2;
  --neon-blue-nb-400: #4d5ef7;
  --neon-blue-nb-300: #727df9;
  --neon-blue-nb-200: #9ea2fa;
  --neon-blue-nb-100: #c6c7fc;
  --neon-blue-nb-050: #e9e9fe;
  --bright-turquoise-bt-900: #006d6f;
  --bright-turquoise-bt-800: #00919f;
  --bright-turquoise-bt-700: #00a6b8;
  --bright-turquoise-bt-600: #00bbd4;
  --bright-turquoise-bt-500: #00cce8;
  --bright-turquoise-bt-400: #00d5eb;
  --bright-turquoise-bt-300: #00dff0;
  --bright-turquoise-bt-200: #19ebf5;
  --bright-turquoise-bt-100: #98f2f8;
  --bright-turquoise-bt-050: #d7fafc;
  --gold-gd-900: #f67300;
  --gold-gd-800: #f89f00;
  --gold-gd-700: #f9b800;
  --gold-gd-600: #fad200;
  --gold-gd-500: #f8e100;
  --gold-gd-400: #fbe63f;
  --gold-gd-300: #fceb66;
  --gold-gd-200: #fdf193;
  --gold-gd-100: #fef7be;
  --gold-gd-050: #fffce5;
  --torch-red-tr-900: #c40003;
  --torch-red-tr-800: #d20014;
  --torch-red-tr-700: #de001d;
  --torch-red-tr-600: #f00022;
  --torch-red-tr-500: #ff151f;
  --torch-red-tr-400: #fa3941;
  --torch-red-tr-300: #f06568;
  --torch-red-tr-200: #f79293;
  --torch-red-tr-100: #ffc9cf;
  --torch-red-tr-050: #ffe9ed;
  --dodger-blue-db-900: #0d47a1;
  --dodger-blue-db-800: #1565c0;
  --dodger-blue-db-700: #1976d2;
  --dodger-blue-db-600: #1e88e5;
  --dodger-blue-db-500: #2196f3;
  --dodger-blue-db-400: #42a5f5;
  --dodger-blue-db-300: #64b5f6;
  --dodger-blue-db-200: #90caf9;
  --dodger-blue-db-100: #bbdefb;
  --dodger-blue-db-050: #e3f2fd;
  --lime-green-lg-900: #007209;
  --lime-green-lg-800: #009420;
  --lime-green-lg-700: #1ea92b;
  --lime-green-lg-600: #3dbd35;
  --lime-green-lg-500: #4ece3d;
  --lime-green-lg-400: #6ed65c;
  --lime-green-lg-300: #8cde7b;
  --lime-green-lg-200: #afe8a2;
  --lime-green-lg-100: #cff1c7;
  --lime-green-lg-050: #ecfae9;
  --white-wh-900: #fff;
  --grey-gr-900: #111827;
  --grey-gr-800: #1f2937;
  --grey-gr-700: #374151;
  --grey-gr-600: #4b5563;
  --grey-gr-500: #6b7280;
  --grey-gr-400: #9ca3af;
  --grey-gr-300: #d1d5db;
  --grey-gr-200: #e5e7eb;
  --grey-gr-100: #f3f4f6;
  --grey-gr-050: #f9fafb;
}
