@media print {
  .klaro_employeeprofile-appbar,
  .MuiBackdrop-root,
  .print-button,
  .showgap-button,
  .MuiDialog-root > .MuiBackdrop-root,
  .MuiDialog-root:not(.klaro_employeeprofile),
  .klaro_employeeprofile .switch-container,
  .klayo-appdrawer {
    display: none !important;
  }

  .MuiDialog-root {
    position: absolute !important;
    height: auto !important;
    width: 100% !important;
    overflow: visible !important;
  }

  .MuiDialogContent-root {
    margin-top: 0 !important;
    padding: 20px 40px !important;
    overflow: visible !important;
    background-color: white !important;
    height: auto !important;
  }

  * {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  .klaro_section {
    break-inside: avoid;
    page-break-inside: avoid;
    margin-bottom: 20px !important;
    box-shadow: none !important;
    border: 1px solid #ddd !important;
  }

  .klayo-table_grid .job-name {
    width: 300px !important;
    min-width: 300px !important;
    max-width: 300px !important;
  }
  .klayo-table_grid .categories {
    width: 200px !important;
    min-width: 200px !important;
    max-width: 200px !important;
  }
  .klayo-table_grid .priority {
    width: 100px !important;
    min-width: 100px !important;
    max-width: 100px !important;
  }
  .klayo-table_grid .status {
    width: 36px !important;
    min-width: 36px !important;
    max-width: 36px !important;
  }

  @page {
    margin: 24px;
    size: A4;
  }
}
